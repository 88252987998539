const FourSquaresOneRectangle = ({ className, style }) => {
  return (
    <svg
      className={className}
      width='431'
      height='271'
      viewBox='0 0 431 271'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={style}
    >
      <path
        d='M230 0H296.838V66.9851H230V0Z'
        fill='#FCF7E6'
      />
      <path
        d='M230 137.015H296.838V204H230V137.015Z'
        fill='#0066CC'
      />
      <path
        d='M363.676 137.015H430.514V204H363.676V137.015Z'
        fill='#FCF7E6'
      />
      <path
        d='M296.838 204H363.676V270.985H296.838V204Z'
        fill='#334C66'
      />
      <path
        d='M0 204H297V271H0V204Z'
        fill='url(#paint0_linear_2079_88079)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_2079_88079'
          x1='297'
          y1='237.5'
          x2='-1.68965e-06'
          y2='237.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#9DE0DE' />
          <stop
            offset='0.78125'
            stopColor='#9DE0DE'
            stopOpacity='0'
          />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default FourSquaresOneRectangle
