import { CompleteHeader, Footer } from '@/molecules'
import PreviewBadge from '@/molecules/PreviewBadge/PreviewBadge'

import { motion } from 'framer-motion'
import Head from 'next/head'
import { useRouter } from 'next/router'
import NProgress from 'nprogress'
import { useEffect } from 'react'

const motionWrapperStyle = {
  // height: '100%',
  // display: 'flex',
  // alignContent: 'center',
  // flexDirection: 'column',
  // justifyContent: 'flex-start',
  // overflowX: 'hidden',
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  gridTemplateColumns: '1fr',
  height: '100dvh',
  overflowX: 'hidden',
  maxWidth: '100dvw',
}

const RootLayout = ({ header, footer, children, title }) => {
  const router = useRouter()
  const isPreview = router.isPreview || false
  const pageTitle = title.replaceAll('-', ' ')
  NProgress.configure({ showSpinner: false })

  useEffect(() => {
    const handleStart = () => NProgress.start()
    const handleComplete = () => NProgress.done()

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleComplete)
    router.events.on('routeChangeError', handleComplete)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleComplete)
      router.events.off('routeChangeError', handleComplete)
    }
  }, [router])

  return (
    <motion.div
      style={motionWrapperStyle}
      key={pageTitle || 'root-layout'}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        stiffness: 60,
        damping: 20,
        duration: 0.5,
      }}
    >
      {isPreview ? <PreviewBadge /> : null}
      <Head>
        <title>{`${pageTitle} - Digital Library`}</title>
        <meta
          name='description'
          content={`
            Pagina ${title} del sito dell'Istituto Centrale per la Digitalizzazione del Patrimonio Culturale
          `}
        />
      </Head>
      <CompleteHeader header={header} />
      <main className='main'>{children}</main>
      <Footer footer={footer} />
    </motion.div>
  )
}

export default RootLayout
