import { Richtext } from '@/atoms'
import Image from 'next/image'
import { handleCustomBGDataAttribute } from 'utils/functions'
import { PixelDecorator } from '..'

const FullImageParagraph = ({ items, ...props }) => {
  const {
    text: title,
    image,
    description,
    useParallax = false,
    objectFit: imageObjectFit = 'cover',
    customBackgroundColor = false,
    pixelDecorator = 'NONE',
    customInset = null,
  } = items

  const pixelDecoratorInsetOffset = {
    A: '-10,0,0,-10',
    B: '-10,0,0,-10',
    C: '0,0,0,0',
  }
  return (
    <section
      className={'full-image-paragraph_section'}
      style={handleCustomBGDataAttribute(customBackgroundColor)}
    >
      <div className={'full-image-paragraph_wrapper'}>
        <div className={'full-image-paragraph_wrapper_paragraph-row'}>
          <h2 className={'full-image-paragraph_wrapper_paragraph-row_title'}>
            {title}
          </h2>
          <Richtext textAlign={'center'}>{description}</Richtext>
        </div>
        <div className={'full-image-paragraph_wrapper_image-row'}>
          <PixelDecorator
            pixelDecorator={pixelDecorator}
            insetOffset={pixelDecoratorInsetOffset}
            inset={customInset}
          >
            {useParallax ? (
              <div
                className={
                  'full-image-paragraph_wrapper_image-row_image-wrapper parallax'
                }
                style={{
                  backgroundImage: `url(${image.url})`,
                }}
              />
            ) : (
              <Image
                src={image.url}
                alt={image.alt}
                fill
                style={{ objectFit: imageObjectFit }}
              />
            )}
          </PixelDecorator>
        </div>
      </div>
    </section>
  )
}

export default FullImageParagraph
