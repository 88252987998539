import { Richtext } from '@/atoms'
import {
  Col,
  Container,
  Icon,
  LinkList,
  LinkListItem,
  Row,
} from 'design-react-kit'
import Link from 'next/link'
import { useEffect, useState } from 'react'
import { withNextLink } from 'utils/functions'
import { MiCFooterLogo } from '../Svgs'

const MiCHref = 'https://www.beniculturali.it/'

const Footer = ({ footer }) => {
  const {
    firstRowElements = [],
    secondRowElements = [],
    contactsElements = [],
    subMenuElements = [],
    followUsElements = [],
  } = footer
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) return null

  return (
    <footer className='it-footer'>
      <div className='it-footer-main'>
        <Container>
          <section>
            <Row className={'clearfix'}>
              <Col
                sm={12}
                className={'py-4'}
              >
                <Link
                  href={MiCHref}
                  target={'_blank'}
                  title={'Vai alla pagina: Ministero della Cultura'}
                >
                  <MiCFooterLogo className={'it-footer-main_footer-logo'} />
                </Link>
              </Col>
            </Row>
          </section>
        </Container>
        <Container>
          <section className='border-white border-top 5'>
            <Row>
              {firstRowElements?.map((item, index) => (
                <FooterCol
                  key={`footer-col-${item.title}-${index}`}
                  title={item.title}
                  url={item.url}
                  subItems={item.children}
                  length={firstRowElements.length}
                />
              ))}
            </Row>
          </section>
          <section className='border-white border-top'>
            <Row className={'contacts'}>
              <FooterContacts {...contactsElements} />
              {secondRowElements?.map((item, index) => (
                <FooterCol
                  key={`footer-col-${item.title}-${index}`}
                  title={item.title}
                  url={item.url}
                  subItems={item.children}
                  length={secondRowElements.length}
                />
              ))}
              <FooterFollowUs {...followUsElements} />
            </Row>
          </section>
        </Container>
      </div>
      <div className='it-footer-small-prints clearfix'>
        <Container>
          <ul className='it-footer-small-prints-list list-inline mb-0 d-flex  flex-row align-items-center flex-wrap'>
            {subMenuElements.children?.length > 0
              ? subMenuElements?.children.map((item, index) => (
                  <FooterSmallPrints
                    key={`footer-small-prints-${item.title}-${index}`}
                    url={item.url}
                    title={item.title}
                  />
                ))
              : null}
          </ul>
        </Container>
      </div>
    </footer>
  )
}

const FooterCol = ({ title, url, subItems }) => {
  const isValidUrl = url && url !== '#'
  return (
    <Col
      className='footer-items-col'
      lg={3}
      md={3}
      sm={6}
    >
      <h3
        style={{
          fontSize: '1.25rem',
        }}
      >
        {isValidUrl ? (
          <Link
            href={url}
            title={`Vai alla pagina: ${title}`}
          >
            {title}
          </Link>
        ) : (
          title
        )}
      </h3>
      <LinkList className='footer-list clearfix'>
        {subItems?.length > 0
          ? subItems.map((subItem, index) =>
              withNextLink(
                <LinkListItem
                  href={subItem.url}
                  title={`Vai alla pagina: ${subItem.title}`}
                  key={`footer-link-${subItem.title}-${index}`}
                >
                  {subItem.title}
                </LinkListItem>,
                subItem.url,
                {},
                {
                  key: `footer-link-${subItem.title}-${index}`,
                },
              ),
            )
          : null}
      </LinkList>
    </Col>
  )
}

const FooterContacts = ({ title, url, text, length }) => {
  const rowSpace = Math.ceil(12 / length)
  const isValidUrl = url && url !== '#'
  return (
    <Col
      className='footer-contacts-col'
      lg={rowSpace}
      md={rowSpace}
    >
      <h3>
        {isValidUrl ? (
          <Link
            href={url}
            title={`Vai alla pagina: ${title}`}
          >
            {title}
          </Link>
        ) : (
          title
        )}
      </h3>
      <Richtext
        textAlign='left'
        className='text-white'
      >
        {text}
      </Richtext>
    </Col>
  )
}

const FooterFollowUs = ({ title, url, length, social_buttons, ...props }) => {
  const rowSpace = Math.ceil(12 / length - 1)
  const isValidUrl = url && url !== '#'
  const getSocialIconName = (item) => {
    return item.social.toLowerCase() === 'x'
      ? 'it-twitter'
      : `it-${item.social.toLowerCase()}`
  }
  return (
    <Col
      className='align-self-center text-center footer-social-col'
      lg={rowSpace}
      md={rowSpace}
    >
      <h3>
        {isValidUrl ? (
          <Link
            href={url}
            title={`Vai alla pagina: ${title}`}
          >
            {title}
          </Link>
        ) : (
          title
        )}
      </h3>
      <ul className='list-inline md-text-center social '>
        {social_buttons?.map(({ social_button: item }, index) => (
          <li
            className='list-inline-item'
            key={`footer-social-${item.social}-${index}`}
          >
            <Link
              href={item.url}
              target='_blank'
              rel='noopener noreferrer'
              className={'p-2 text-white'}
              title={`Vai alla pagina: ${item.social}`}
            >
              <Icon
                className='align-top'
                color='white'
                icon={getSocialIconName(item)}
                size='sm'
              />
            </Link>
          </li>
        ))}
      </ul>
    </Col>
  )
}

const FooterSmallPrints = ({ url, title }) => (
  <li className='list-inline-item'>
    <Link
      href={url}
      target={'_blank'}
      rel={'noopener noreferrer'}
      title={`Vai alla pagina: ${title}`}
    >
      {title}
    </Link>
  </li>
)

export default Footer
