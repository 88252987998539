import { AccordionList, Richtext } from '@/atoms'
import { handleCustomBGDataAttribute } from 'utils/functions'

const mainClassName = 'faq'

const FAQ = ({ items }) => {
  const {
    title,
    description,
    customBackgroundColor = false,
    items: _items,
  } = items

  const hasItems = _items && _items.length > 0
  return (
    <section
      className={`${mainClassName + '_section'}`}
      style={handleCustomBGDataAttribute(customBackgroundColor)}
    >
      <div className={`${mainClassName + '_section_container'}`}>
        <div className={`${mainClassName + '_section_container_paragraph'}`}>
          <h2
            className={`${
              mainClassName + '_section_container_paragraph_title'
            }`}
          >
            {title}
          </h2>
          <Richtext
            className={`${
              mainClassName + '_section_container_paragraph_description'
            }`}
            textAlign={'left'}
          >
            {description}
          </Richtext>
        </div>
        {hasItems ? (
          <div className={`${mainClassName + '_section_container_faq-list'}`}>
            <AccordionList
              isMutuallyExclusive
              items={_items}
            />
          </div>
        ) : null}
      </div>
    </section>
  )
}

export default FAQ
