import Link from 'next/link'

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const capitalizeSentence = (string) => {
  const words = string.split(' ')
  return words.map((word) => capitalize(word)).join(' ')
}

export const getReadableDate = (date) => {
  const [day, month, year] = date.split('/')
  const _date = new Date(year, month - 1, day)
  const options = { day: 'numeric', month: 'long', year: 'numeric' }
  return _date.toLocaleDateString('it-IT', options)
}

export const disableScroll = (isDisabled) => {
  document.querySelector('html').style.overflow = isDisabled ? 'hidden' : 'auto'
}

export const formatMapUrl = (mapString) => {
  let response = mapString

  if (mapString.includes('<')) {
    const regex = /<iframe.*?src=["'](.*?)["']/
    const match = mapString.match(regex)

    if (match && match[1]) {
      response = match[1]
    }
  }

  return `${response}&zoom=16`
}

export const withNextLink = (children, href, customStyle, linkProps) => {
  const isLegacy = typeof children !== 'string'
  if (!href) return children
  return (
    <Link
      href={href}
      passHref
      legacyBehavior={isLegacy}
      style={{
        textDecoration: 'inherit',
        cursor: 'pointer',
        ...customStyle,
      }}
      {...linkProps}
    >
      {children}
    </Link>
  )
}

export const countCharsInHtmlString = (htmlString = null) => {
  if (!htmlString) return 0
  const text = htmlString.replace(/<[^>]*>?/gm, '')
  return text.length
}

export const isHexColorString = (color) => {
  return /^#([0-9A-F]{3}|[0-9A-F]{6})$/i.test(color)
}

export const handleCustomBGDataAttribute = (customBackgroundColor) => {
  return isHexColorString(customBackgroundColor)
    ? { backgroundColor: `${customBackgroundColor} !important` }
    : { backgroundColor: '#E3DECE !important' }
}
