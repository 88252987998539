import { LearnMoreCard, Richtext } from '@/atoms'
import { handleCustomBGDataAttribute } from 'utils/functions'

const mainClassName = 'content-cards-list'

const ContentCardsList = ({ items }) => {
  const {
    title,
    description,
    customBackgroundColor = false,
    items: cards,
  } = items
  return (
    <section
      className={`${mainClassName + '_section'}`}
      style={handleCustomBGDataAttribute(customBackgroundColor)}
    >
      <div className={`${mainClassName + '_section_container'}`}>
        <div className={`${mainClassName + '_section_container_paragraph'}`}>
          <h2
            className={`${
              mainClassName + '_section_container_paragraph_title'
            }`}
          >
            {title}
          </h2>
          <Richtext
            className={`${
              mainClassName + '_section_container_paragraph_description'
            }`}
            textAlign={'left'}
          >
            {description}
          </Richtext>
        </div>
        <div className={`${mainClassName + '_section_container_list'}`}>
          {cards.length > 0
            ? cards.map((card, index) => (
                <LearnMoreCard
                  key={`${card.title}-${index}`}
                  {...card}
                  cardOrder={index}
                />
              ))
            : null}
        </div>
      </div>
    </section>
  )
}

export default ContentCardsList
