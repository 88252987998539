import { Richtext } from '@/atoms'
import Image from 'next/image'
import { PixelDecorator } from '..'

const mainClassName = 'paragraph-with-image'
const ParagraphWithImage = ({ items }) => {
  const {
    text: title,
    description,
    image,
    objectFit: imageObjectFit = 'cover',
    pixelDecorator = 'NONE',
    customInset = null,
  } = items
  const pixelDecoratorInsetOffset = {
    A: '-5,0,0,-20',
    B: '0,0,0,-20',
    C: '0,0,0,0',
  }
  return (
    <section className={`${mainClassName + '_section'}`}>
      <div className={`${mainClassName + '_section_container'}`}>
        <div className={`${mainClassName + '_section_container_text'}`}>
          <h2 className={`${mainClassName + '_section_container_text_title'}`}>
            {title}
          </h2>
          <Richtext
            textAlign={'left'}
            className={`${
              mainClassName + '_section_container_text_description'
            }`}
            isDefault={false}
          >
            {description}
          </Richtext>
        </div>
        <div className={`${mainClassName + '_section_container_image'}`}>
          <PixelDecorator
            // pixelDecorator={pixelDecorator}
            pixelDecorator={'A'}
            insetOffset={pixelDecoratorInsetOffset}
            inset={customInset}
          >
            <Image
              src={image.url}
              alt={image.alt}
              fill
              title={image.title}
              style={{ objectFit: imageObjectFit }}
            />
          </PixelDecorator>
        </div>
      </div>
    </section>
  )
}

export default ParagraphWithImage
