import DLHero from '@/molecules/Svgs/DL'
import { Hero, HeroBody, HeroTitle } from 'design-react-kit'
import { useMediaQuery } from 'hooks'
import Image from 'next/image'
import { PixelDecorator } from '..'
import { ThreeRectangles, TwoRectangles } from '../Svgs'

const MainHero = ({ items, ...rest }) => {
  const {
    title,
    description,
    image,
    useParallax,
    objectFit: imageObjectFit = 'cover',
    pixelDecorator = 'NONE',
    customInset = null,
  } = items
  const isDesktop = useMediaQuery('lg')

  const descriptionDisplayClass = isDesktop ? 'display-4' : 'display-5'

  const pixelDecoratorInsetOffset = {
    A: '0,0,0,0',
    B: '0,0,0,0',
    C: '0,0,0,0',
  }
  return (
    <section className={'main-hero-section'}>
      <Hero overlay='dark'>
        <div className={' img-responsive-wrapper'}>
          <PixelDecorator
            pixelDecorator={pixelDecorator}
            insetOffset={pixelDecoratorInsetOffset}
            hideOnMobile={true}
            inset={customInset}
          >
            {useParallax ? (
              <div
                className={'main-hero_bg-img parallax '}
                style={{
                  backgroundImage: `url(${image.url ?? image})`,
                }}
              />
            ) : (
              <Image
                className={'main-hero_bg-img '}
                src={image.url ?? image}
                fill
                title={image.title}
                alt={image.alt ?? 'Immagine di sfondo'}
                fetchPriority={'high'}
                style={{ objectFit: imageObjectFit }}
              />
            )}
          </PixelDecorator>
        </div>

        <DLHero className={'main-hero_dl-svg'} />

        <HeroBody>
          <TwoRectangles className={'main-hero_two_svg'} />
          <ThreeRectangles className={'main-hero_three_svg'} />
          <HeroTitle className={'main-hero_title '}>{title}</HeroTitle>
          <h2
            className={`main-hero_description text-white ${descriptionDisplayClass}`}
          >
            {description}
          </h2>
        </HeroBody>
      </Hero>
    </section>
  )
}

{
  /* <HeroCategory>Category</HeroCategory> */
  /* <HeroButton color='secondary'>Label button</HeroButton> */
}
export default MainHero
