import {
  FourSquaresOneRectangle,
  ThreeRectangles,
  TwoRectangles,
} from '../Svgs'

const PIXEL_DECORATORS = {
  A: ThreeRectangles,
  // B: SixRectangles,
  B: FourSquaresOneRectangle,
  C: TwoRectangles,
  // C: FourSquaresOneRectangle,
  NONE: () => null,
}

const insetByDecorator = {
  A: '50,0,0,50',
  B: '50,0,0,50',
  C: '50,0,0,50',
}

const PixelDecorator = ({
  pixelDecorator = 'A',
  inset = null,
  insetOffset = {
    A: '0,0,0,0',
    B: '0,0,0,0',
    C: '0,0,0,0',
  },
  isPercentage = true,
  hideOnMobile = false,
  children,
}) => {
  const isNoneOrInvalid =
    pixelDecorator === 'NONE' || !PIXEL_DECORATORS[pixelDecorator]
  if (isNoneOrInvalid) {
    return children
  }
  const PixelDecoratorComponent = PIXEL_DECORATORS[pixelDecorator]
  const [topOffset, rightOffset, bottomOffset, leftOffset] =
    insetOffset[pixelDecorator].split(',')
  const isCustomInsetValid = inset && inset.split(',').length === 4
  const [top, right, bottom, left] = isCustomInsetValid
    ? inset.split(',')
    : insetByDecorator[pixelDecorator].split(',')
  const unitMeasure = isPercentage ? '%' : 'px'
  const decoratorClass = `${pixelDecorator.toLowerCase()}-shape`

  const style = {
    top: `${Number(top) + Number(topOffset)}${unitMeasure}`,
    right: `${Number(right) + Number(rightOffset)}${unitMeasure}`,
    bottom: `${Number(bottom) + Number(bottomOffset)}${unitMeasure}`,
    left: `${Number(left) + Number(leftOffset)}${unitMeasure}`,
  }

  return (
    <div className='pixel-decorator'>
      {children}
      <PixelDecoratorComponent
        className={`pixel-decorator_svg ${decoratorClass}
        ${hideOnMobile ? 'mobile-hidden' : ''} `}
        style={style}
      />
    </div>
  )
}

export default PixelDecorator
