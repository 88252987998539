import { Breadcrumbs, Richtext } from '@/atoms'
import Image from 'next/image'
import { handleCustomBGDataAttribute } from 'utils/functions'
import { PixelDecorator } from '..'

const BottomImageHero = ({ items, isHero }) => {
  const mainClassName = 'bottom-image-hero'
  const {
    title,
    description,
    image,
    objectFit: imageObjectFit = 'cover',
    customBackgroundColor = false,
    pixelDecorator = 'NONE',
    customInset = null,
  } = items
  const hasImage = image && typeof image.url === 'string'
  const pixelDecoratorInsetOffset = {
    A: '0,0,0,0',
    B: '0,0,0,0',
    C: '0,0,0,0',
  }
  return (
    <section
      className={`${mainClassName + '_section'}`}
      data-has-image={hasImage}
      style={handleCustomBGDataAttribute(customBackgroundColor)}
    >
      {isHero ? <Breadcrumbs /> : null}
      <div
        className={`${mainClassName + '_wrapper'}`}
        data-has-image={hasImage}
      >
        <div className={`${mainClassName + '_wrapper_paragraph-row'}`}>
          <h1 className={`${mainClassName + '_wrapper_paragraph-row_title'}`}>
            {title}
          </h1>
          <div
            className={`${
              mainClassName + '_wrapper_paragraph-row_description-wrapper'
            }`}
          >
            <Richtext textAlign={'left'}>{description}</Richtext>
          </div>
        </div>
        {hasImage ? (
          <div className={`${mainClassName + '_wrapper_image-row'}`}>
            <PixelDecorator
              pixelDecorator={pixelDecorator}
              insetOffset={pixelDecoratorInsetOffset}
              inset={customInset}
            >
              <Image
                src={image.url}
                alt={image.alt}
                fill
                style={{ objectFit: imageObjectFit }}
              />
            </PixelDecorator>
          </div>
        ) : null}
      </div>
    </section>
  )
}

export default BottomImageHero
