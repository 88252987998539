const TwoRectangles = ({ className, style }) => (
  <svg
    className={className}
    width='69'
    height='48'
    viewBox='0 0 69 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    style={style}
  >
    <path
      d='M0 0L23 0L23 24L0 24L0 0Z'
      fill='#0066CC'
    />
    <path
      d='M23 24L46 24V48H23L23 24Z'
      fill='#FCF7E6'
    />
  </svg>
)

export default TwoRectangles
