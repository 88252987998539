import { Button, Richtext } from '@/atoms'
import { Hero, HeroBody } from 'design-react-kit'
import Image from 'next/image'
import { PixelDecorator } from '..'
import { ReactagleGradient } from '../Svgs'

const mainClassName = 'contacts'

const ContactsHero = ({ items, ...props }) => {
  const {
    text: title,
    description,
    image,
    cta,
    useParallax = false,
    pixelDecorator = 'A',
    customInset = null,
  } = items
  const pixelDecoratorInsetOffset = {
    A: '-10,0,0,20',
    B: '-10,0,0,20',
    C: '0,0,0,30',
  }
  return (
    <section className={`${mainClassName + '_section'}`}>
      <Hero overlay='dark'>
        <div className='img-responsive-wrapper'>
          <div className='img-responsive'>
            <div className='img-wrapper'>
              <PixelDecorator
                pixelDecorator={pixelDecorator}
                insetOffset={pixelDecoratorInsetOffset}
                inset={customInset}
                hideOnMobile={true}
              >
                {useParallax ? (
                  <div
                    className={`${mainClassName + '_section_bg-img'} parallax `}
                    style={{
                      backgroundImage: `url(${image.url ?? image})`,
                      backgroundPosition: 'top center',
                    }}
                  />
                ) : (
                  <Image
                    className={`${mainClassName + '_section_bg-img'}`}
                    src={image.url ?? image}
                    fill
                    title={image.title}
                    alt={image.alt ?? 'Immagine di sfondo'}
                    fetchPriority={'high'}
                  />
                )}
              </PixelDecorator>
            </div>
          </div>
        </div>
        <ReactagleGradient
          className={`${mainClassName + '_section_svg-gradient'}`}
        />
        <HeroBody className={`${mainClassName + '_section_text-wrapper'}`}>
          <h2 className={`${mainClassName + '_section_text-wrapper_title'}`}>
            {title}
          </h2>
          <div
            className={`${mainClassName + '_section_text-wrapper_description'}`}
          >
            <Richtext
              text={description}
              textAlign={'left'}
            >
              {description}
            </Richtext>
          </div>
          <Button
            href={cta?.url}
            fullWidth={false}
            variant={'inverted'}
            internalUrl={cta?.internal_url}
            isInternalPage={cta?.internal_page}
            openInNewTab={cta?.open_link_in_new_tab}
            className={`${mainClassName + '_section_text-wrapper_cta'}`}
          >
            {cta.label}
          </Button>
        </HeroBody>
      </Hero>
    </section>
  )
}

export default ContactsHero
